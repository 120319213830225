import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import getApiUrl from '../components/config';

const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);

// Define the userDetails state interface
interface UserDetailsState {
  data: any | null; // Data coming from the userDetails API, initially null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'; // Status of fetch action
  error: boolean | null; // Error status, initially null
}

// Initial state
const initialState: UserDetailsState = {
  data: null,
  status: 'idle',
  error: null,
};

// Thunk to fetch userDetails data from the backend
export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/userDetails/userInfo`, { withCredentials: true });
      console.log("Response from userInfo API", response); // Console output to check the response
      return response.data?.userDetails; // Assuming the user details are in response.data.data
    } catch (err: any) {
      console.error(err.response?.data?.message || err.message);
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch user details');
    }
  }
);

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.data = {...state.data , ...action.payload }
      state.status = 'succeeded';
      state.error = null;
      console.log("Updated state in setUserDetails:", state);
      return state;
    },
    deleteUserDetails: (state) => {
      state.data = null; // Clear the userDetails data
      state.status = 'idle';
      state.error = null;
      console.log("Updated state in deleteUserDetails:", state);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = 'loading';
        return state;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
        return state;
      })
      .addCase(fetchUserDetails.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = true;
        state.data = null;
        return state;
      });
  },
});

// Export actions and reducer

export const userDetailsActions = userDetailsSlice.actions; // reducer userDetailsActions.setUserDetails
export default userDetailsSlice;
