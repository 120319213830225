import React from 'react';
import axios from 'axios';

const LoginWithCerner = () => {
  const handleLogin = async () => {
    try {
      // Redirect user to the Cerner login page
      window.location.href = 'https://test.qurenote.com/api/auth/cerner';
    } catch (error) {
      console.error("Error during Cerner login:", error);
    }
  };

  return (
    <button onClick={handleLogin}>
      Login with Cerner
    </button>
  );
};

export default LoginWithCerner;