const DarkMode = {
    iconColor: "#8DC9FD",
    iconColorOnSelection: "#1990FF",
    titleAndText: "#8DC9FD",
    titleAndTextOnSelection: "#1990FF",
    scrollBarBackground: "#002F5B",
    scrollBarTab: "#1990FF",
    selectionBackground: "#6EA5D8",
    titleInsideFieldSelection: "#38383B",
    subTitlesOnFieldSelection: "#07080B",
    iconOnSelection: "#1990FF",
    textInsideInputFieldsDefault: "#07080B",
    notesFieldTitleAndTexts: "#FFFFFF",
    notesFieldOnSelectionGlowingBorder: "#0086FF",
    noteFieldBackgroundOnSelection: "#002D56",
    border: "#1990FF",
    headingBackground: "#80C2FF",
    headingText: "#00070D",
    rowBackground: ["#0D0D0D", "#002342"],
    rowText: "#FFFFFF",
    rightSubBackground: "#004F93",
    buttonBackground: "#0D0D0D",
    buttonText: "#1990FF",
    buttonBackgroundOnSelection: "#1990FF",
    buttonTextOnSelection: "#0D0D0D",
    categoryMenuBackground: "#282929",
    defaultIcons: "#8DC9FD",
    iconsOnSelection: "#1990FF",
    title: "#B0B6C8",
    titleOnSelection: "#1990FF"
  };
  
  export default DarkMode;
  