// deviceUtils.js

export function getDeviceType() {
    const ua = navigator.userAgent;

    // Check for mobile devices (phones)
    if (/Mobi|Android/i.test(ua)) {
        return 'Phone login';
    }

    // Check for tablets
    if (/Tablet|iPad/i.test(ua)) {
        return 'Tablet login';
    }

    // Check for laptops and desktops
    if (/Windows|Macintosh|Linux|X11/i.test(ua)) {
        // Further check screen size to differentiate between laptops and desktops
        if (window.innerWidth <= 1366 && window.innerHeight <= 768) {
            return 'Laptop login';
        }
        return 'Desktop login';
    }

    // Default to 'unknown' if it does not fit any criteria
    return 'unknown';
}
