import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import getApiUrl from '../components/config';
const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);

// Define the user state interface
interface UserState {
  data: any | null; // Data coming from one file, initially null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'; // Status of fetch action
  error: boolean | null; // Error message, initially null
}

// Initial state
const initialState: UserState = {
  data: null,
  status: 'idle',
  error: null,
};

// Thunk to fetch user data from the backend
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/user/me`,{withCredentials : true});
      console.log("response from user me api",response); // Console output to check the response
      return response.data?.user;
    } catch (err: any) {
      console.error(err.response?.data?.message || err.message);
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch user data');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.data = {...state.data , ...action.payload };
      state.status = 'succeeded';
      state.error = null;
      console.log("Updated state in setUser:", state);
      return state;
    },
    deleteUser: (state) => {
      state.data = null; // Clear the user data
      state.status = 'idle';
      state.error = null;
      console.log("Updated state in deleteUser:", state);
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
        return state;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
        return state;
      })
      .addCase(fetchUser.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = true;
        state.data = null;
        return state;
      });
  },
});

// Export actions and reducer

export const  userActions = userSlice.actions;  //reducer userActions.setReducer
export default userSlice;
