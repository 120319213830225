 
const getApiUrl = (baseUrl:any): string => {
    console.log(baseUrl);
    switch (baseUrl) {
        case 'dev':
            return 'https://dev.qurenote.com';
            break
        case 'prod':
            return 'https://qurenote.com' ;
            break
        case 'test':
            return 'https://test.qurenote.com' ;
            break
        default:
            return 'http://localhost:8000';
    }
};

export default  getApiUrl;
