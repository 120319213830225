// import { FunctionComponent } from 'react';

import styles from './TermsAndConditions.module.css'; // Import the CSS file for styling

const TermsAndConditions = () => {
    return (
        <div className={styles.termsAndConditionsPrivacy}>
            <div className={styles.header}>
                <img className="logoImg" src="/icons/logo.svg" alt="Logo"/> {/*need to upload the svg file of logo*/}
                <h1 className={styles.color}>Privacy Policy</h1>
                <h2 className={styles.color}> We Value Your Privacy</h2>
            </div>
            <br></br>
            <br></br>
            <p className={styles.information}>
                These Terms and Conditions (“Terms”) describe the rights and obligations 
                of QureNOTE , a Kansas State corporation (“QureNOTE”) and QureNOTE’s customer 
                (the “Customer”) (collectively, the “Parties”) related to QureNOTE’s provision 
                to Customer, and Customer’s use of, QureNOTE’s Services (as defined below). Details 
                related to the Services, including payment amounts, usage limitations, and other terms, 
                shall be set forth in one or more separate statements of work, purchase orders, or 
                other related purchasing documents previously executed by the Parties or to be executed 
                by the Parties (each, an “SOW” and, together with these Terms, the “Agreement”). Each 
                SOW is subject to these Terms. The terms of an SOW shall govern in the event of a 
                conflict between these Terms and an SOW. The Agreement shall be effective upon Customer’s 
                execution of the initial SOW (the “Effective Date”).
            </p>
            <p><b className={styles.bold}>RECITALS.</b></p>
            <p>Customer desires to license from QureNOTE 
            its artificial intelligence-based medical scribe software (the “Software”) for use by Customer’s 
            healthcare employees or contractors (the “Providers”) to facilitate the recording, 
            storage, transmission and delivery of the Customer’s transcripts. Providers will 
            dictate patient-provider conversations to be recorded through the Software (the 
            “Recordings”).</p>
            <p className={styles.information}><b className={styles.bold}>AGREEMENT.</b> </p>
            <p>The Parties agree as follows:</p>
            <div>
                <h3 className={styles.headings}>1. Services.</h3>
                <p className={styles.information}><b className={styles.subheading}>1.1. Services.</b> QureNOTE will provide Customer the following services (collectively, the “Services”): (a) use of its Software and Hardware as specified in this Section 1 and in the applicable SOW; (b) the schedule of deliverables as set forth in an SOW (the “Deliverables”), if any; (c) support installation, set-up, and testing of the Hardware in designated rooms within Customer’s designated locations, if specified in an SOW (the “Locations”); (d) onboarding, training, and on-going support as set forth below; and (e) development, integration, and support for the Customer’s Electronic Health Record (“EHR”) application (“EHR Integration”), if specified in an SOW.</p>
                <p className={styles.information}><b className={styles.subheading}>1.2. Use of Software.</b> Subject to the other limitations on use described in this Agreement, Customer is hereby authorized to use the number of licenses of the Software (the “Users”) or the number of Recordings as set forth in an SOW. Customer and its Providers may use the Software solely for the purposes and pursuant to the terms and conditions set forth in this Agreement, and shall not, without the prior written approval of QureNOTE, allow any other party to use the Software for any other purpose or for the benefit of any such party. QureNOTE will comply with the terms and conditions of the Business Associate Agreement (the “BAA”), attached here to as, Exhibit A, as it relates to Customer’s use of the Software. QureNOTE will use commercially reasonable efforts to provide Customer the Software. Customer hereby agrees that, unless otherwise specified in an SOW, the Software will operate on a standalone basis without integration with the Customer’s EHR.</p>
                <p className={styles.information}><b className={styles.subheading}>1.3. Hardware.</b> Unless otherwise specified in an SOW, Customer shall be solely responsible for providing and furnishing any mobile phone, audio recording device and/or other equipment necessary to utilize the Software (the “Hardware”). If specified in an SOW that QureNOTE will provide the Hardware, QureNOTE shall furnish Customer the type and quantity of Hardware as set forth in such SOW solely for Customer’s use in connection with the Services. Customer agrees that its use of any Hardware provided by QureNOTE shall be subject to the terms and conditions of this Agreement.</p>
                <p className={styles.information}><b className={styles.subheading}>1.4. IT Support.</b> QureNOTE will provide Customer with reasonable IT Support as needed via phone and/or email during QureNOTE’s standard business hours.</p>
                <p className={styles.information}><b className={styles.subheading}>1.5. Software Changes.</b> QureNOTE reserves the right to make changes to the Software from time to time including upgrades, enhancements, bug-fixes, and other modifications to improve the Software, provided that such changes shall not eliminate or materially interfere with the core functionality of the Software.</p>
            </div>
            <div>
                <h3 className={styles.headings}>2. Term and Termination.</h3>
                <p className={styles.information}><b className={styles.subheading}>2.1. Term.</b> Each SOW will have a term applicable to such SOW, as set forth therein. This Agreement will commence on the Effective Date and will continue until the completion of any SOWs executed hereunder (the “Term”). In the event that the Term expires, and any subsequent SOWs are executed, the term of this Agreement shall automatically renew for the duration of such subsequent SOW(s). In the event that no subsequent SOWs are executed, the Agreement will automatically renew for a subsequent Term as set forth in the original SOW.</p>
                <p className={styles.information}><b className={styles.subheading}>2.2. Termination.</b> Notwithstanding Section 2.1, this Agreement may be terminated as follows:</p>
                <p className={styles.information}><b className={styles.subheading}>2.2.1. Termination at End of Term.</b> Customer may terminate this agreement by providing written notice to QureNOTE (billing@QureNOTE.com) prior to the end of the Initial Term, or during a subsequent Term. Termination will become effective at the end of the Initial Term as set forth in an SOW, or at the end of the current Term if Customer has renewed for a successive Term, unless otherwise noted in an SOW.</p>
                <p className={styles.information}><b className={styles.subheading}>2.2.2. Material Breach.</b> Either Party may terminate this Agreement in the event the other Party breaches any material provision of this Agreement and fails to cure such breach within thirty (30) days after receipt from the breaching Party of notice specifying the breach requiring its remedy, unless such material breach cannot be cured, in which case the non-breaching Party can terminate this Agreement with immediate effect.</p>
                <p className={styles.information}><b className={styles.subheading}>2.2.3. Insolvency.</b> In the event Customer files for protection of bankruptcy laws, makes an assignment for the benefit of creditors, appoints or suffers appointment of a receiver or trustee over its property, files a petition under any bankruptcy or insolvency act or has any such petition filed against it which is not discharged within sixty (60) days of the filing thereof, then QureNOTE may terminate this Agreement effective immediately upon written notice to Customer.</p>
                <p className={styles.information}><b className={styles.subheading}>2.3. Effect of Termination.</b> On the effective date of any termination of this Agreement (“Termination Date”), Customer will cease any and all further use of the Services and will certify to QureNOTE in writing that the Software has been removed from Customer’s internal systems and is no longer accessible. All Fees (as defined below) earned prior to the Termination Date shall be immediately due and payable. Customer will return any QureNOTE-provided Hardware to QureNOTE at the destination designated by QureNOTE within thirty (30) days of the Termination Date. Customer shall be responsible for any risk of loss, damage or destruction of such Hardware while in transit to QureNOTE and until it has been received by QureNOTE. Any QureNOTE-provided Hardware that is either not returned within the thirty (30) day return period or is damaged such that it is not suitable for reuse (as reasonably determined by QureNOTE) will incur a Replacement Fee as set forth in an SOW.</p>
                <p className={styles.information}><b className={styles.subheading}>2.4. Survival.</b> All sections of this Agreement that by their nature and context are intended to survive termination, including but not limited to Sections 2.3, 2.4, and 4 through 9, shall survive any expiration or termination of this Agreement.</p>
                <p className={styles.information}><b className={styles.subheading}>2.5 No Cancellation Policy.</b> The customer acknowledges and agrees that they are not permitted to cancel their Services for any reason, including but not limited to change of mind, buyer's remorse, or dissatisfaction with the Services. Customer acknowledges and agrees that they have had the opportunity to review the features and functionality of the Software and Services prior to entering into this Agreement and that they accept the Software and Services "as is" as defined in Section 7 of the Terms.<br /><br />
                    In the event that the Customer experiences any issues or concerns with the Software or Services provided, Customer agrees to communicate these concerns to QureNOTE’s support team, who will endeavor to address and resolve such concerns in a timely and professional manner. Customer further acknowledges and agrees that any Fees or other payments made to QureNOTE are non-refundable and that Customer is responsible for the full payment of all Fees associated with the Services for the entire term of the Agreement, regardless of any early termination or cancellation attempts. Customer hereby expressly waives its right to dispute or initiate chargebacks through banks or payment processors, including Stripe, for any reason related to the Services.</p><br></br>
                <p className={styles.information}><b className={styles.subheading}>2.6. Arbitration.</b> In the event that a dispute arises between the Customer and QureNOTE related to the Terms or the Services, and such dispute cannot be resolved through negotiation or mediation, the parties agree to submit the dispute to binding arbitration. The arbitration shall be conducted in accordance with the rules of a mutually agreed-upon arbitration organization. The arbitration shall take place in San Francisco, California. The decision of the arbitrator(s) shall be final and binding on both parties, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Each party shall be responsible for their own legal fees and expenses, unless the arbitrator(s) determines that one party is entitled to an award of legal fees and expenses from the other party. The parties agree that the arbitration process shall be conducted confidentially and that the results of the arbitration shall be maintained as confidential by both parties, to the fullest extent permitted by law.</p>
            </div>
            <div>
                <h3 className={styles.headings}>3. Payment of Fees.</h3>
                <p className={styles.information}><b className={styles.subheading}>3.1. Implementation Fees.</b> Customer shall pay to QureNOTE the implementation fees (“Implementation Fees”) as set forth in the applicable SOW. If specified in an SOW, QureNOTE may also provide the Customer dedicated staff as necessary to develop EHR Integration between the Software and the Customer’s EHR. If QureNOTE performs EHR Integration for the Customer, Customer may also be subject to an “EHR Integration Fee” as set forth forth in the applicable SOW. The total Implementation Fees, including the EHR Integration Fee, if any, may vary depending on the complexity of the implementation. Implementation Fees are non-refundable unless otherwise noted and mutually agreed upon.</p>
                <p className={styles.information}><b className={styles.subheading}>3.2. Subscription Fees.</b> Customer shall pay to QureNOTE the subscription fees (“Subscription Fees”) as set forth in the applicable SOW. If Customer’s use of the Services exceeds the number of User(s) or Recording(s) as set forth in an SOW, the Subscription Fees set forth in the SOW will be adjusted accordingly. Subscription Fees are non-refundable unless otherwise noted and mutually agreed upon.</p>
                <p className={styles.information}><b className={styles.subheading}>3.3. Additional Fees.</b> If QureNOTE provides Customer any additional services as mutually agreed upon by the Parties (the “Additional Services”), then additional fees may apply (the “Additional Fees” and together with the EHR Integration Fee and the Monthly Subscription Fees, the “Fees”) as set forth in an SOW. Additional Fees are non-refundable unless otherwise noted and mutually agreed upon.</p>
                <p className={styles.information}><b className={styles.subheading}>3.4. Late Fees.</b> Any Fees that are unpaid, in part or in full, past the applicable due date then, Customer shall be responsible for any costs and expenses resulting from or arising out of any overdue balance (including, but not limited to, reasonable attorneys’ fees). Late Fees are non-refundable.</p>
                <p className={styles.information}><b className={styles.subheading}>3.5. Payment Method.</b> All Fees shall be charged to the payment method designated by Customer upon signing up for the Services. Customer hereby authorizes QureNOTE to automatically process the Fees as they become due. Customer is responsible for providing QureNOTE with an accurate, complete, and up-to-date payment method at all times. Customer agrees and acknowledges that QureNOTE uses third-party payment processors, and that Customer will have to agree to the terms and conditions of such third-party payment processors.</p>
                <p className={styles.information}><b className={styles.subheading}>3.6. Taxes.</b> The Fees do not include taxes and other governmental charges, as may be appropriate under law. Unless Parties agree in writing, Customer will be responsible for any use tax, sales tax, excise tax, custom duty, inspection or testing fee, or any other taxes, fees, duties or charges imposed by any governmental authority, relating to or measured by the transaction, in addition to the prices quoted or invoiced in the Statement of Work. If QureNOTE is required to pay any such taxes, custom duties, fees or charges, Customer shall reimburse QureNOTE thereof or provide QureNOTE an exemption certificate or other document acceptable to the authority imposing the taxes, duties, fees, or charges at the time the order is placed.</p>
            </div>
            <div>
                <h3 className={styles.headings}>4. Proprietary Rights.</h3>
                <p className={styles.information}><b className={styles.subheading}>4.1. Software.</b> QureNOTE owns and retains all right, title and interest in and to: (a) the Software, all improvements, enhancements or modifications thereto; (b) any software, applications, inventions or other technology developed in connection with the Services; and (c) all intellectual property rights related to any of the foregoing. Subject to the terms and conditions of this Agreement, QureNOTE hereby grants to Customer a non-perpetual, non-exclusive, non-transferable, non-sublicensable right and license, during the Term, to access and use the Software, and all of its features and functionality, solely for the purposes set forth in this Agreement. Subject to Customer’s confidentiality obligations hereunder, Customer may make reasonable use of the data resulting from the use of the Software as necessary to support its use of the Services under this Agreement.</p>
                <p className={styles.information}><b className={styles.subheading}>4.2. Hardware.</b>This Section shall apply only with respect to QureNOTE-provided Hardware, if any, as specified in Section 1.3. Title to the Hardware shall at all times remain with QureNOTE. The Parties agree that, subject to the terms and conditions of this Agreement, QureNOTE is leasing the Hardware to Customer during the Term for the purpose set forth herein. Customer will bear the risk of loss or damage to the Hardware at all times, including if the Hardware is damaged, lost or stolen and regardless of cause or fault, and Customer will be solely responsible for maintaining all risk property insurance providing coverage for the Hardware while the Hardware is in the Customer’s possession. In the event that any of the Hardware is materially damaged, lost, or stolen while in Customer’s possession, due to any cause, then Customer shall pay QureNOTE for the cost to repair or replace the damaged Hardware in the amount as reasonably determined by QureNOTE. The following shall be determined in the reasonable discretion of QureNOTE: (a) whether the Hardware is materially damaged, lost, or stolen; (b) whether such Hardware must be repaired or replaced; and (c) the cost to repair or replace such damaged Hardware. The cost for ordinary wear and tear to the Hardware that is expected to occur when the Hardware is used for its ordinary and intended purpose shall not be charged to Customer. Customer must report any accidents, damages, or incidents of theft or vandalism to QureNOTE as soon as any such event is discovered by Customer.</p>
                <p className={styles.information}><b className={styles.subheading}>4.3. QureNOTE Content.</b>QureNOTE will retain all right, title and interest it may have in and to the Recordings and any written transcripts, voice recordings, reports, notes, requirements documents, specifications, materials, flow charts, notes, outlines and the like, and any data contained in any of the foregoing (collectively, “QureNOTE Content”), in each case that are developed, conceived or made by QureNOTE or the Software in connection with Customer’s use of the Services. Customer has no right to use or disclose any QureNOTE Content except for use of the Services as set forth in this Agreement. Subject to QureNOTE’s compliance with the terms of the BAA, QureNOTE shall have the right to collect and analyze data and other information relating to the provision, use, and performance of various aspects of the Services and related technologies (including, without limitation, information concerning QureNOTE Content and data contained therein), and QureNOTE will be free to: (a) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other QureNOTE offerings; and (b) disclose such data solely in aggregate or other anonymized, de-identified form in connection with its business.</p>
                <p className={styles.information}><b className={styles.subheading}>4.4. Feedback.</b> Customer and its personnel (including the Providers) may provide ideas, suggestions, comments, or other feedback regarding any part of the Services, including ideas for new or improved products or technologies, product enhancements, processes, materials, marketing plans or new product names relating to the Services (collectively “Feedback”). QureNOTE shall own any and all Feedback provided to QureNOTE. To the extent that QureNOTE does not own any Feedback, Customer hereby grants to QureNOTE an exclusive, perpetual, royalty-free license to use the Feedback for its business purposes, and neither Customer nor its personnel shall be entitled to any compensation for such Feedback.</p>
                <p className={styles.information}><b className={styles.subheading}>4.5. Publicity Consent.</b> Unless otherwise agreed to by the Parties in writing, QureNOTE may use Customer’s name and/or logo to refer to Customer as a customer of QureNOTE on its website and other marketing materials.</p>
                <p className={styles.information}><b className={styles.subheading}>4.6. Third-party Messaging.</b> QureNOTE uses Intercom as a direct messaging platform. Intercom's HIPAA-compliant service allows users to send and receive messages with QureNOTE's Customer Support team. To provide effective support, Intercom will collect the following: email and telephone number, IP address of your device, Your QureNOTE User ID, History of which support articles you access, Usage data. Enabling Intercom allows for seamless and rapid communication with QureNOTE's support team.</p>
            </div>
            <div>
                <h3 className={styles.headings}>5. Restrictions; Representation & Warranties; Responsibilities.</h3>
                <p className={styles.information}><b className={styles.subheading}>5.1. Restrictions.</b> Customer will not, under any circumstances, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or the Software, or any documentation or data related to the Services or the Software; modify, translate, or create derivative works based on the Services or the Software (except to the extent expressly permitted by QureNOTE or authorized within the Services or the Software); use the Services or the Software for the benefit of a third party; or remove any proprietary notices or labels from the Services or the Software.</p>
                <p className={styles.information}><b className={styles.subheading}>5.2. Customer’s Representations and Warranties.</b> Customer represents, covenants, and warrants that Customer will use the Services and the Software only in compliance with the terms of this Agreement and any instructions provided by QureNOTE. Although QureNOTE has no obligation to monitor Customer’s use of the Services or the Software, QureNOTE may do so and may prohibit any use of the Services or the Software it believes may be (or alleged to be) in violation of this Agreement.</p>
                <p className={styles.information}><b className={styles.subheading}>5.3. Customer’s Responsibilities.</b></p>
                <p className={styles.information}><b className={styles.subheading}>5.3.1. Assistance.</b> Customer shall: (a) ensure that QureNOTE’s technical specifications requirements are met for the Services; (b) if specified in an SOW, provide reasonable assistance for EHR Integration; (c) if specified in an SOW, provide on-site access for QureNOTE to perform installation, testing, onboarding and support; (d) provide reasonable assistance for Provider’s on-boarding process; and (e) track and produce internally generated KPI reports to QureNOTE for evaluation decisions.</p>
                <p className={styles.information}><b className={styles.subheading}>5.3.2. Customer Equipment. </b>Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access, or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Customer Equipment”). Customer shall also be responsible for maintaining the security of the Customer Equipment, any customer accounts, passwords and files for all uses related to the Services.</p>
                <p className={styles.information}><b className={styles.subheading}>5.3.3. Compliance.</b> Customer shall ensure that its use of the Software and the Services complies with all applicable laws, including, without limitation, the Health Insurance Portability and Accountability Act (“HIPAA”) and other data privacy laws. Customer represents and warrants that it shall be solely responsible for obtaining and maintaining any and all consents, authorizations, or permissions that maybe required by the HIPAA Privacy Rule, 42 CFR Part 2 (“Confidentiality of Substance Use Disorder Patient Records or other applicable federal or state data privacy laws and regulations before disclosing to Business Associate the Protected Health Information pertaining to an Individual. Without limiting the generality of the foregoing, Customer shall not request QureNOTE to use or disclose Protected Health Information (as defined in the BAA) in any manner that would not be permissible under HIPAA if done by Customer (unless permitted by HIPAA for a Business Associate).</p>
                <p className={styles.information}><b className={styles.subheading}>5.4. Suspension of Services.</b> . Not withstanding anything to the contrary in this Agreement, QureNOTE may temporarily suspend, or permanently revoke, Customer's access to any portion or all of the Services if QureNOTE reasonably determines that: (a) there is a threat or attack on the Software; (b) Customer's use of the Software disrupts or poses a security risk to the Software; or (c) Customer is using the Software for fraudulent or illegal activities or otherwise in violation of the terms of this Agreement (any such temporary suspension, a "Suspension" and any such revocation, a "Revocation"). QureNOTE shall use commercially reasonable efforts to provide three (3) days’ written notice of any Suspension or Revocation to Customer and, in cases of Suspensions, to provide updates regarding resumption of access to the Software following any Suspension. In cases of Suspensions, QureNOTE shall use commercially reasonable efforts to resume providing access to the Software as soon as reasonably practicable after the event giving rise to the Suspension is cured. QureNOTE shall have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences that Customer or any third party may incur as a result of a Suspension or Revocation, and Customer shall not be entitled to any refunds of any Fees on account of any Suspension or Revocation.</p>
            </div>
            <div>
                <h3 className={styles.headings}>6. Confidentiality.</h3>
                <p className={styles.information}><b className={styles.subheading}>6.1. Confidential Information.</b> During the course of this Agreement, each Party (the “Disclosing Party”) may disclose to the other Party (the “Receiving Party”) certain non-public information or materials relating to the Disclosing Party’s products, intellectual property, business, business plans, marketing programs and efforts, customer lists, customer information, financial information and other confidential information and trade secrets (“Confidential Information”). Confidential Information shall also include QureNOTE Content.</p>
                <p className={styles.information}><b className={styles.subheading}>6.2. Exclusions.</b> Confidential Information does not include information that Protected Health Information which is defined and addressed in the Business Associate Agreement between the parties, and: (a) is or becomes publicly available through no breach by the Receiving Party of this Agreement; (b) was previously known to the Receiving Party prior to the date of disclosure, as evidenced by contemporaneous written records; (c) was acquired from a third party without any breach of any obligation of confidentiality; (d) was independently developed by the Receiving Party hereto without reference to Confidential Information of the Disclosing Party; or (e) is required to be disclosed pursuant to a subpoena or other similar order of any court or government agency, provided, however, that the Receiving Party upon receiving such subpoena or order shall: (i) promptly inform the Disclosing Party in writing and provide a copy thereof; (ii) reasonably cooperate with the Disclosing Party in limiting disclosure of the Disclosing Party’s Confidential Information; and (iii) only disclose that Confidential Information necessary to comply with such subpoena or order.</p>
                <p className={styles.information}><b className={styles.subheading}>6.3. Protection of Confidential Information.</b> Except as expressly provided herein, the Receiving Party will not use or disclose any Confidential Information of the Disclosing Party without the Disclosing Party’s prior written consent, except disclosure to and subsequent uses by the Receiving Party’s authorized employees, consultants, attorneys, accountants and other representatives (collectively, the “Representatives”) on a need-to-know basis, provided that such Representatives are, by virtue of written agreements or legal obligations, such to confidentiality obligations with respect to the Confidential Information that are at least as restrictive as the Receiving Party’s obligations under this Section 6. Subject to the foregoing nondisclosure and non-use obligations, the Receiving Party agrees to use at least the same care and precaution in protecting such Confidential Information as the Receiving Party uses to protect the Receiving Party’s own Confidential Information and trade secrets, and in no event less than reasonable care. Each Party acknowledges that due to the unique nature of the other Party’s Confidential Information, the Disclosing Party will not have an adequate remedy in money or damages in the event of any unauthorized use or disclosure of its Confidential Information. In addition to any other remedies that may be available in law, in equity or otherwise, the Disclosing Party shall be entitled to seek injunctive relief to prevent such unauthorized use or disclosure. Neither Party shall remove or alter any proprietary markings (e.g., copyright and trademark notices) on the other Party’s Confidential Information.</p>
                <p className={styles.information}><b className={styles.subheading}>6.4. Return of Confidential Information.</b> On the Disclosing Party’s written request or upon expiration or termination of this Agreement (unless the Parties mutually agree to enter into a continuing business relationship), the Receiving Party will promptly: (a) return or destroy, at the Disclosing Party’s option, all originals and copies of all documents and materials it has received containing the Disclosing Party’s Confidential Information; and (b) deliver or destroy, at the Disclosing Party’s option, all originals and copies of all summaries, records, descriptions, modifications, negatives, drawings, adoptions and other documents or materials, whether in writing or in machine-readable form, prepared by the Receiving Party, prepared under its direction, or at its request from the documents and materials referred to in subparagraph (a), and provide a notarized written statement to the Disclosing Party certifying that all documents and materials referred to in subparagraphs (a) and (b) have been delivered to the Disclosing Party or destroyed, as requested by the Disclosing Party. Notwithstanding the foregoing, the Receiving Party may retain a copy of the Disclosing Party’s Confidential Information solely for archival purposes or as otherwise required by law, provided that any Confidential Information so retained shall continue to be subject to the confidentiality obligations in this Section 6.</p>
            </div>
            <div>
                <h3 className={styles.headings}>7. Disclaimer. </h3>
                <p className={styles.information}>CUSTOMER AGREES AND ACKNOWLEDGES THAT THE SERVICES AND THE SOFTWARE ARE PROVIDED “AS IS” AND QURENOTE HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY AND ALL WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, LOSS OF DATA, ACCURACY OF RESULTS, OR OTHERWISE ARISING FROM A COURSE OF DEALING OR RELIANCE. QURENOTE DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT THE SERVICES OR THE SOFTWARE WILL BE COMPATIBLE WITH ANY PARTICULAR DEVICE OR EHR, OR THAT THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES OR THE SOFTWARE OR ANY DATA PROVIDED BY QURENOTE THROUGH THE SERVICE OR THE SOFTWARE WILL BE ACCURATE. QURENOTE SPECIFICALLY DISCLAIMS ALL RESPONSIBILITY FOR ANY THIRD-PARTY HARDWARE, SOFTWARE, PRODUCTS, OR SERVICES PROVIDED WITH OR INCORPORATED INTO THE SERVICES OR THE SOFTWARE.</p>
            </div>
            <div>
                <h3 className={styles.headings}>8. Indemnification; Limitation of Liability.</h3>
                <p className={styles.information}><b className={styles.subheading}>8.1. Indemnification.</b> Customer will indemnify, defend at QureNOTE’s request, and hold harmless QureNOTE and its directors, officers, employees, agents and other representatives (“Indemnified Parties”) against any loss, damage, liability, settlements, costs or expenses (including reasonable attorneys' fees and expenses) in connection with claims, demands, suits or proceedings (“Claims”) made or brought against QureNOTE by a third party arising from or relating to: (a) any breach of any representation or warranty of Customer contained in this Agreement; (b) any bodily injury or property damage related to the provision of or use of the Hardware; (c) any breach by Customer of its obligations hereunder, including without limitation, any breach of Confidential Information of QureNOTE; or (d) the intentional or reckless acts or omissions of Customer’s personnel or of any other person or entity acting for, on behalf of, at the direction of, or under the control of Customer.</p>
                <p className={styles.information}><b className={styles.subheading}>8.2. Limitation of Liability.</b> TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL QURENOTE BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS, REVENUE, DATA, PROFITS OR GOODWILL) ARISING OUT OF THIS AGREEMENT, EVEN IF QURENOTE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND IN NO EVENT SHALL THE AGGREGATE LIABILITY OF QURENOTE ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE FEES PAID BY CUSTOMER HEREUNDER .</p>
            </div>
            <div>
                <h3 className={styles.headings}>9. Non-Disparagement. </h3>
                <p className={styles.information}><b className={styles.subheading}>9.1 Non-Disparagement.</b> As part of the 
                Agreement between the Parties, the Customer agrees not to engage in any form of 
                disparagement towards the Company. This includes refraining from making negative 
                comments, reviews, or testimonials about the Company, its reputation, products, 
                services, employees, or business practices. <br /><br />
                The prohibition on disparagement applies to various settings, including but not 
                limited to:
                <p>1. Online Platforms: The Customer shall not post negative comments, reviews, or 
                testimonials about the Company on social media platforms, online forums, or any other 
                public digital spaces.</p>
                <p>2. Public Speaking Engagements: The Customer shall not make negative statements or 
                derogatory remarks about the Company during public speaking engagements, conferences, 
                or industry events.</p>
                <p>3. Written Communications: The Customer shall refrain from making negative comments or 
                disparagement in written communications such as emails, letters, press releases, or any 
                other form of publication.</p>
                <p>4. Private Conversations: The Customer shall maintain professionalism and avoid making 
                negative comments or engaging in disparaging discussions about the Company in private 
                conversations with third parties.</p>
                <p>The purpose of this clause is to ensure a mutually respectful and professional business 
                relationship between the Company and the Customer. By adhering to this clause, the 
                Customer acknowledges the importance of maintaining a positive image and reputation 
                for the Company.</p>
                <p>The terms set forth in this clause will remain in effect during the term of the 
                Agreement, any subsequent terms, and in perpetuity in the event either party pursues 
                termination.</p>
                <p>In the event of a breach of this clause, the Company may seek legal remedies, which 
                may include:</p> 
                <p>Injunctive Relief: The Company may seek a court order to restrain the Customer from 
                making further disparaging comments or to remove existing negative comments.</p>
                <p>Damages: The Company may seek monetary damages as compensation for any harm caused by the breach 
                of the non-disparagement clause. This could include financial losses, damage to 
                reputation, or other quantifiable harm.</p> 
                <p>Specific Performance: In some cases, the Company may request specific performance, where the Customer is required to fulfill 
                their obligations under the non-disparagement clause or take specific actions to 
                remedy the harm caused. The choice of legal remedies will depend on the nature and 
                extent of the breach, and the applicable laws governing the agreement. It is important 
                to note that this clause does not restrict the Customer from providing honest feedback, 
                constructive criticism, or expressing concerns directly to the Company in a private 
                and respectful manner, with the intention of improving their business relationship or 
                resolving any issues.</p> 
                <p>This Non-Disparagement Clause is an integral part of the overall 
                agreement between the Company and the Customer. By agreeing to the terms, the Customer 
                acknowledges their understanding and acceptance of the non-disparagement obligations 
                outlined in this clause.</p></p>
            </div>
            <div>
                <h3 className={styles.headings}>10. General.</h3>
                <p className={styles.information}><b className={styles.subheading}>10.1. Authority.</b> Each Party represents and warrants to the other that: (a) it has full power and authority to enter into and perform this Agreement and the execution and delivery of this Agreement has been duly authorized; and (b) such Party’s performance of its obligations under this Agreement will not: (i) violate any regulations or applicable law, (ii) breach any other agreement to which such Party is a party or is bound; or (iii) violate any obligation owed by such Party to any third party.</p>
                <p className={styles.information}><b className={styles.subheading}>10.2. Applicable Law.</b> This Agreement is and will be governed by and construed in accordance with the laws of the State of California, USA, without giving effect to the principles of conflicts of laws of any jurisdiction. Exclusive venue for any action, claim, proceeding or suit related to this Agreement will be the federal and state courts located in San Francisco County, State of California, USA. Customer irrevocably consents to the personal jurisdiction of such courts.</p>
                <p className={styles.information}><b className={styles.subheading}>10.3. Assignment.</b> Customer may not assign or transfer this Agreement or any rights herein or delegate any duties herein without the prior written consent of QureNOTE. QureNOTE may sublicense the Services and may assign or transfer this Agreement in connection with a merger, consolidation, reorganization, or sale of all or substantially all of its assets. Any attempted assignment, transfer or delegation in contravention of this Section is null and void. This Agreement will be binding upon and inure to the benefit of any successors and permitted assigns.</p>
                <p className={styles.information}><b className={styles.subheading}>10.4. Modifications.</b> Both Parties hereby agree that QureNOTE may, at its sole discretion, update or modify the Terms contained herein at any time and without notice and that Customer agrees to be bound by any such updates or modifications to the Terms; provided; however, that any updates or modifications to any terms and conditions contained in an SOW shall be agreed upon in writing and signed by duly authorized representatives of the Parties.</p>
                <p className={styles.information}><b className={styles.subheading}>10.5. Entire Agreement.</b> This Agreement, including the Exhibits hereto, constitutes the entire agreement between the Parties relating to the subject matter hereof, and there are no other representations, understandings or agreements between the Parties relating to the subject matter hereof. The Parties may use standard business forms or other communications, but use of such forms is for convenience only and does not alter the provisions of this Agreement. No modifications or amendments to this Agreement will be valid unless in writing and signed by duly authorized representatives of the Parties.</p>
                <p className={styles.information}><b className={styles.subheading}>10.6. Nonwaiver.</b> Any failure or delay by either Party to exercise or partially exercise any right, power or privilege under this Agreement will not be deemed a waiver of any such right, power or privilege. No waiver by either Party of a breach of any term, provision or condition of this Agreement by the other Party will constitute a waiver of any succeeding breach of the same or any other provision in this Agreement. No waiver will be valid unless executed in writing by the Party making the waiver.</p>
                <p className={styles.information}><b className={styles.subheading}>10.7. Force Majeure.</b> The failure to perform or delay in performance by either Party shall be excused to the extent that performance is rendered commercially impracticable by strike, fire, flood, terrorism, governmental acts or orders or restrictions, or any other reason where a Party’s failure to perform is beyond such Party’s reasonable control and not caused by the negligence of such Party (each, a “Force Majeure Event”). In the event that either Party’s performance is directly impacted by a Force Majeure Event, such Party shall provide prompt notice to the other Party.</p>
                <p className={styles.information}><b className={styles.subheading}>10.8. Relationship of the Parties.</b> Nothing in this Agreement will create or be deemed to create a partnership or the relationship of employer and employee between the Parties. Nothing shall prohibit either Party from entering into an agreement for the same or similar Services from any other third party.</p>
                <p className={styles.information}><b className={styles.subheading}>10.9. Severability.</b> If any term of this Agreement will to any extent be held invalid or unenforceable by a court of competent jurisdiction, the remainder of this Agreement will not be affected thereby, and each term will be valid and enforceable to the fullest extent permitted by law.</p>
                <p className={styles.information}><b className={styles.subheading}>10.10. Notices.</b> Any notice required to be given hereunder may be delivered by email at the email addresses provided below the signature of the Parties at the end of this Agreement, which notice shall be deemed delivered within twenty-four (24) hours of being sent.</p>
                <p className={styles.information}><b className={styles.subheading}>10.11. Counterparts.</b> This Agreement may be executed in counterparts, each of which is an original, including scanned copies or those transmitted by facsimile, and all of which together evidence the same agreement, each of which together constitute one and the same.</p>
                <p className={styles.information}><b className={styles.subheading}>10.12. Use of Headings.</b> The headings and contained in this Agreement are for convenience of reference purposes only and shall not affect in any way the meaning or interpretation of this Agreement.(Remainder of page intentionally left blank.)<br /><br />
                <p><b className={styles.bold}>EXHIBIT A.</b></p>
                <p><b className={styles.bold}>Business Associate Agreement</b></p>
                <p className={styles.information}><b className={styles.subheading}>1. Definitions:</b> Capitalized terms used, but not otherwise defined herein, shall have the meaning as defined in the Terms and Conditions or in the or in 45 CFR Parts 160, 162 and 164.</p>
                <p className={styles.information}><b className={styles.subheading}>1.1.</b> “Business Associate” shall have the meaning given to such term under the Privacy and Security Rules, including but not limited to, 45 CFR §160.103.</p>
                <p className={styles.information}><b className={styles.subheading}>1.2.</b> “Covered Entity” shall have the meaning given to such term under the Privacy and Security Rules, including, but not limited to, 45 CFR §160.103.</p>
                <p className={styles.information}><b className={styles.subheading}>1.3.</b> “HIPAA” shall mean the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191.</p>
                <p className={styles.information}><b className={styles.subheading}>1.4.</b> “Privacy Rule” shall mean the Standards for Privacy of Individually Identifiable Health Information at 45 CFR Parts 160 and 164.</p>
                <p className={styles.information}><b className={styles.subheading}>1.5.</b> “Protected Health Information” or “PHI” means any information, transmitted or recorded in any form or medium that: (a) relates to the past, present or future physical or mental condition of an individual, the provision of health care to an individual, or the past, present or future for the provision of health care to an individual; and (b) identifies the individual or with respect to which there is a reasonable basis to believe the information can be used to identify the individual, and shall have the meaning given to such term under HIPAA and the HIPAA Regulations at 45 CFR Parts 160, 162 and 164, including, but not limited to 45 CFR §164.501.</p>
                <p className={styles.information}><b className={styles.subheading}>1.6.</b> “Security Rule” shall mean the Security Standards at 45 CFR Parts 160, 162 and 164.</p></p>
                <div>
                    <p className={styles.information}><b className={styles.subheading}>2. Business Associate Obligations.</b> The Parties hereby agree that Business Associate shall be permitted to access, use and/or disclose PHI provided by or obtained on behalf of Covered Entity for the following state purposes, except as otherwise limited in this BAA:</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1. Permitted Uses and Disclosures.</b> Business Associate contracts with and provides digital data movement, access and storage services via its desktop client and network connected voice recorder, to medical transcriptionists, medical transcription companies, health care providers, and other Covered Entities and Business Associates that use and access PHI</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.</b> In the course of providing services to clients, including Covered Entity, Business Associate, its employees, representatives, agents, subcontractors occasionally are required to access PHI. Business Associate ensures that in such cases its agents, subcontractors who access PHI agree to substantially the same, but no less stringent terms and conditions as are applicable but not limited to the Business Associate as set forth herein.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.1.</b> The Business Associate shall provide medical transcription and scribing services to its clients, including Covered Entity, using a proprietary automated system.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.2.</b> Document access will be recorded via an audit trail associated with every document, and all user access to PHI will be captured and stored within said audit trail and available to Covered Entity.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.3.</b> PHI will be retained in an encrypted format while residing on Amazon Web Services servers.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.4.</b> Audio dictations of PHI will be destroyed upon receipt of written request of Covered Entity.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.5.</b> PHI in document form is retained on Business Associate’s servers for the duration of relationship with Covered Entity. Upon termination of this BAA, PHI in document form will be destroyed in accordance with Section 6 of this BAA.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.1.1.6.</b> Business Associate will use raw data (voice recordings and PHI collected during the recordings) and secondary generated data (transcription, data labels, medical ontologies, etc.) for the following services, which include but is not limited to, medical documentation, medical transcription, quality assurance, software improvements, algorithm training, a voice analytics.</p>
                    <b className={styles.subheading}>2.3 Specific Use and Disclosure Provisions.</b>
                    <p className={styles.information}><b className={styles.subheading}>2.3.1</b> Except as otherwise limited in this Agreement, Business Associate may use and disclose PHI for the proper management and administration of the Business Associate or to meet its legal responsibilities; provided, however, that such PHI may only be disclosed for such purposes only if the disclosures are required by law or the Business Associate obtains certain reasonable assurances from the person to whom the information is disclosed.  The required reasonable assurances are that:</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.1.1.</b> the information will remain confidential;</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.1.2.</b> the information will be used or further disclosed only as required by law or for the purpose for which the information was disclosed to the person; and</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.1.3.</b> the person will notify Business Associate of any instances of which it is aware in which the confidentiality of the information has been breached.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.2.</b> Business Associate may use and disclose PHI to report violations of law to appropriate Federal and State authorities, consistent with 45 C.F.R. § 164.502(j)(1).</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.3. Data Aggregation.</b> Business Associate may provide data aggregation services relating to the health care operations of the Covered Entity as permitted by 45 CFR § 164.504(e)(2)(i)(B).</p>
                    <p className={styles.information}><b className={styles.subheading}>2.3.4. De-Identification.</b> Business Associate may de-identify Protected Health Information, provided that such de-identification is performed in accordance with 45 CFR §164.514(b). The parties agree that such de-identified information is no longer deemed PHI under the HIPAA Standards.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.4. Nondisclosure.</b> Business Associate shall not use or further disclose PHI other than as permitted or required by this BAA or as required by law.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.5. Safeguards.</b> Business Associate shall use appropriate safeguards to prevent use or disclosure of PHI other than as provided for by this BAA. Business Associate shall maintain a comprehensive written information privacy and security program that includes administrative, technical and physical safeguards appropriate to the size and complexity of the Business Associate’s operations and the nature and scope of its activities, including, but not limited to, the safeguards listed above.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.6. Reporting of Disclosure; Mitigation.</b> Business Associate shall report to Covered Entity any use or disclosure of PHI not provided for by this BAA of which Business Associate becomes aware. Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a use or disclosure of PHI by Business Associate in violation of the requirements of this BAA.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.7. Business Associate’s Agents.</b> Business Associate shall ensure that any agents or employees of Business Associate, including subcontractors, to whom it provides PHI received from (or created or received by Business Associate on behalf of) Covered Entity pursuant to this BAA agree to substantially the same, but no less stringent restrictions and conditions that apply to Business Associate with respect to such PHI.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.8. Availability of Information to Covered Entity.</b> BusinessAssociate shall make available to Covered Entity (or, as directed in writing by Covered Entity, to an Individual) such information as Covered Entity may request, and in a reasonable time and manner, to fulfill Covered Entity’s obligations (if any) to provide access to, provide a copy of, PHI pursuant to HIPAA and the Privacy Rule, including, but not limited to, 45 CFR §§ 164.524. In the event any individual requests access to PHI directly from BusinessAssociate, Business Associate shall promptly notify Covered Entity of such request so that Covered Entity can respond directly to such individual in accordance with 45 C.F.R. § 164.524.  Any denials of access to the PHI requested by an individual shall be the responsibility of Covered Entity.</p>                        <p className={styles.information}><b className={styles.subheading}>2.9. Amendment of PHI.</b> Within fifteen (15) business days of receipt of a written request from Covered Entity, Business Associate shall make any amendments to PHI in a designated record set that the Covered Entity directs or agrees to at the request of Covered Entity or an Individual, in order to fulfill CoveredEntity’s obligations (if any) to amend PHI pursuant to HIPAA and the Privacy Rule, including, but not limited to, 45 CFR§164.526, and Business Associate shall, as directed by Covered Entity, incorporate any amendments to PHI into copies of such PHI maintained by Business Associate. In the event any individual requests amendment to PHI directly from Business Associate, BusinessAssociate shall notify Covered Entity of such request so that Covered Entity can respond directly to such individual in accordance with 45 C.F.R. § 164.526.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.10. Internal Practices.</b> BusinessAssociate shall make its internal practices, books and records relating to the use and disclosure of PHI received from Covered Entity (or created or received by Business Associate on behalf ofCovered Entity) available to the Secretary, in a time and manner designated by theSecretary, for purposes of the Secretary determining each party’s compliance with HIPAA and the Privacy Rule.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.11. Documentation of Disclosure for Accounting.</b> To the extent applicable, Business Associate agrees to document such disclosures of PHI and information related to such disclosures as would be required for Covered Entity to respond to a request by an Individual for an accounting of disclosures of PHI in accordance with 45 CFR 164.528.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.12. Accounting of Disclosures.</b> Within thirty(30) days of receipt of written notice from the Covered Entity that CoveredEntity has received a request by an individual for an accounting of disclosures of PHI, Business Associate agrees to provide to Covered Entity or, as instructed by Covered Entity in writing, to an Individual, information collected in accordance with Section 2.9 of this BAA so as to permit Covered Entity to respond to a request by an Individual for an accounting of disclosures of PHI in accordance with 45 CFR 164.528.</p>
                    <p className={styles.information}><b className={styles.subheading}>2.13 Reporting Requirements.</b> Business Associate agrees to report to Covered Entity within fifteen (15) business days from the date Business Associate knew or reasonably should have known, any of the following: <br />
                        (1)   Any use or disclosure of unsecured PHI not permitted by this Agreement of which Business Associate becomes aware. <br />
                        (2)   Any SecurityIncident of which BusinessAssociate becomes aware. <br />
                        (3)   The discovery of a Breach of UnsecuredPHI. <br />
                        <p>For purposes of this Agreement,“Security Incident” shall mean the successful unauthorized access, use, disclosure, modification or destruction of PHI. The Parties acknowledge and agree that thisSection 2.11 constitutes notice by Business Associate to Covered Entity of the ongoing existence and occurrence of attempted but Unsuccessful SecurityIncidents (as defined herein). “Unsuccessful Security Incidents” shall include, but not be limited to, pings and other broadcast attacks on Business Associate’s firewall, port scans, unsuccessful log-on attempts, denials of service, malware such as worms or viruses and any combination of the above, so long as such incidents do not result in unauthorized access, use or disclosure, modification or destruction of PHI.</p>
                    </p>
                    <b className={styles.subheading}>3. Covered Entity Obligations.</b>
                    <p className={styles.information}><b className={styles.subheading}>3.1.</b> Covered Entity shall be responsible for using appropriate safeguards to maintain and ensure the confidentiality, privacy and security of PHI transmitted to Business Associate pursuant to the provision of Services and this BAA, in accordance with the standards and requirements of HIPAA and the Privacy Rule, until such PHI is received by Business Associate.</p>
                    <p className={styles.information}><b className={styles.subheading}>3.2.</b> Covered Entity shall provide BusinessAssociate with the notice of privacy practices that Covered Entity produces in accordance with 45 CFR 164.520 and shall promptly notify Business Associate in writing of any changes or modifications to such notice, to the extent such changes may affect Business Associate’s Use or Disclosure of PHI.</p>
                    <p className={styles.information}><b className={styles.subheading}>3.3.</b>  Covered Entity shall promptly notify Business Associate in writing of any changes in, or revocation of, permission by an Individual to use or disclose PHI, if such changes affect Business Associate’s permitted or required uses or disclosures.</p>
                    <p className={styles.information}><b className={styles.subheading}>3.4.</b> Covered Entity shall promptly notify BusinessAssociate in writing of any restriction to the use or disclosure of PHI thatCovered Entity has agreed to in accordance with 45 CFR 164.522, if such restriction affects Business Associate’s permitted or required uses or disclosures.</p>
                    <p className={styles.information}><b className={styles.subheading}>3.5.</b> Covered Entity shall provide Business Associate with such information as is reasonably necessary to enable Business Associate to perform its obligations under this BAA. Covered Entity may wish to provide a means for Business Associate to properly spell patient names and demographics, either by clearly spelling such information during dictation or by faxing daily patient lists. Business Associate uses commercially reasonable efforts to achieve a high level of accuracy but is not responsible for errors or omissions in the transcript.</p>
                    <b className={styles.subheading}>4. Records.</b>
                    <p className={styles.information}> BusinessAssociate shall make its internal practices, books, and records relating to theUse and Disclosure of PHI received from or created or received by BusinessAssociate on behalf of, Covered Entity available to the Secretary upon written request for purposes of determining compliance with the HIPAA Standards.</p>
                    <b className={styles.subheading}>5. Term & Termination.</b>
                    <p className={styles.information}><b className={styles.subheading}>5.1. Term.</b>  This Agreement shall continue in effect until the later of (a) termination or expiration of the underlying Agreement or (b) when all of the PHI provided by CoveredEntity to Business Associate or created, received, maintained, or transmitted by Business Associate on behalf of Covered Entity is destroyed or returned toCovered Entity in accordance with Section 5(c) below.  </p>
                    <p className={styles.information}><b className={styles.subheading}>5.2. Termination for Cause.</b> Upon eitherParty’s knowledge of a material breach by the other Party, the terminatingParty shall notify the other Party in writing and provide an opportunity for the breaching Party to cure the breach or end the violation within thirty (30) days of such notice and terminate this Agreement if the breaching Party does not cure the breach or end the violation within the time specified. If a cure is not reasonably possible, the terminating Party may immediately terminate this Agreement and any such other agreement upon its knowledge of the material breach, upon written notice to the other Party.</p>
                    <p className={styles.information}><b className={styles.subheading}>5.3. Effect of Termination.</b> Upon termination of this Agreement for any reason, Business Associate shall return or destroy (as directed by Covered Entity) all PHI received from Covered Entity or created or received by Business Associate or any Subcontractor on behalf of CoveredEntity and neither Business Associate nor any Subcontractor shall retain copies of the PHI. In the event BusinessAssociate determines that returning or destroying (as directed by Covered Entity) the PHI is infeasible, Business Associate shall extend the protections of this Agreement to such PHI and limit further uses and disclosures of such PHI to those purposes that make the return or destruction infeasible, for so long as Business Associate maintains such PHI.</p>
                    <p className={styles.information}><b className={styles.subheading}>6. Limitation of Liability.</b> In no event shall Business Associate (or any director, officer or employee or any entity controlling, controlled by or under common control with business associate) be liable to Covered Entity or to any third party for any special, consequential, incidental, or indirect damages, however caused and under any theory of liability arising out of this BAA, whether or not advised of the possibility of such damages, and notwithstanding any failure of essential purpose of any limited remedy. In the event that Business Associate is held liable arising out of or relating to this BAA or the obligations of Business Associate under this BAA, Business Associate’s aggregate liability under any legal theory, including tort claims, shall not exceed the Fees paid and to be paid by Covered Entity pursuant to the Terms and Conditions within the twelve (12) month period prior to such event occurring that gives rise to such liability.</p>
                    <p className={styles.information}><b className={styles.subheading}>7. Compliance Amendments.</b> The Parties acknowledge that state and federal laws relating to electronic data security and privacy are rapidly evolving and that amendment of this BAA may be required to provide for procedures to ensure compliance with such developments. The Parties specifically agree to take such action as is necessary to implement the standards and requirements of HIPAA, the Privacy Rule and other applicable laws relating to the security or confidentiality of PHI.</p>
                    <p className={styles.information}><b className={styles.subheading}>8. No Third-Party Beneficiaries.</b> Nothing in this BAA is intended to confer, nor shall anything herein confer, upon any person other than Covered Entity, Business Associate and their respective successors and assigns, any rights, remedies, obligations or liabilities whatsoever and no other person or entity shall be a third-party beneficiary of this BAA.</p>
                    <p className={styles.information}><b className={styles.subheading}>9. Interpretation.</b> This BAA shall be interpreted as broadly as necessary to implement and comply with HIPAA, the Privacy Rule and any other applicable law relating to security and privacy of PHI. Any ambiguity in this BAA shall be resolved in favor of a meaning that permits each party to comply with the HIPAA regulations and should there be any conflict between the terms of this BAA and related terms in the underlying Agreement, the terms of this BAA shall govern.</p>
                    <p className={styles.information}><b className={styles.subheading}>10. Regulatory Reference.</b> A reference in this BAA to a section in the HIPAA regulations means the section as in effect or as amended, and for which compliance is required.</p><br></br><br></br>
                </div>
                <br></br>
            </div>
            <footer className={styles.footerStyle}>
                <h3>QureNotes.ai</h3>
                <b>Version 1.0</b>
                <p style={{ fontStyle: 'italic' }}>Updated on January 15th, 2024</p>
                <p>&copy; 2024</p>
            </footer>
            <br></br>
            <br></br>
        </div>
    );
};

export default TermsAndConditions;